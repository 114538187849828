.hx-staff-container {
  > * {
    width: 1024px;
    margin: auto;
  }

  > div {
    box-shadow: rgba(0, 0, 0, 0.15) 0 0.15385em 0.30769em 0;
    border-radius: 0.30769em;
    overflow: hidden;
  }

  > h4 {
    letter-spacing: -.01667em;
    line-height: 1.33333em;
    padding: 0;
    font-weight: 400;
  }
}

.hx-staff-profile-info {
  min-height: 19.69231em;
  display: flex;


  > aside {
    width: 26.15385em;
    display: flex;
    justify-content: center;
    min-height: 256px;

    > * {
      height: 2.33333em;
      width: 2.33333em;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 60px;
      font-weight: 700;
    }

    + div {
      width: 46.15385em;
    }
  }

  > div {
    .title {
      font-size: 2.46154em;
      letter-spacing: -0.00625em;
      line-height: normal;
    }

    .row .giant {
        line-height: normal;
    }

  }

  ul {
    list-style-type: none;
    margin: 0;
  }
}

.hx-staff-info-list {
  .huge {
    line-height: normal;
  }

  .hx-list-item {
    &:hover {
      cursor: pointer;
      background-color: rgba(0, 0, 0, .03);
    }

    &:last-child .bb1 {
      border-bottom: 0;
    }
  }
}
