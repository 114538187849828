.hx-auth {
  display: flex;
  align-items: center;
  height: 100%;
  flex-direction: column;

  .hx-auth-container {
    top: 37.5%;
    transform: translateY(-50%);
    position: relative;

    .logo {
      width: 147px;
      height: 32px;
      background: transparent url(../assets/logo-b.png) no-repeat;
      background-size: contain;
    }

    > .hx-form {
      margin-top: 24px;
      border-radius: 8px;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.26);
      background-color: #ffffff;
      padding: 32px 64px;
      font-size: 11px;

      header h3 {
        font-size: 20px;
        line-height: 1.2;
        letter-spacing: -0.3px;
        color: #4e5e7a;
      }

      section {
        margin-top: 16px;
        display: flex;
        flex-wrap: wrap;

        label {
          margin-top: 16px;
          display: block;
          position: relative;

          > .hx-form-label {
            display: block;
            font-size: 10px;
            font-weight: 900;
            line-height: 1.2;
            letter-spacing: 0.5px;
            color: #808fa4;
            text-transform: uppercase;
            vertical-align: middle;
            user-select: none;

            a {
              text-decoration: underline;
              color: inherit;

              &:focus {
                text-decoration-style: dashed;
                text-decoration-color: #4e5e7a;
              }
            }
          }

          input {
            margin-top: 2px;
            border-radius: 6px;
            border: solid 2px #bfcbde;
            background-color: #ffffff;
            display: block;
            height: 28px;
            line-height: 24px;
            width: 100%;
            padding: 0 6px;
            font-size: 13px;
            font-weight: 600;
            color: #808fa4;

            &:focus + .hx-validation-infoblock {
              display: inline-block;
            }

            &[type='checkbox']:focus {
              outline: initial;
              background-color: #aaa;
            }
          }

          &[data-error] {
            input {
              border-color: #f44336;
            }

            &::after {
              content: attr(data-error);
              color: #e00;
              font-size: 10px;
              position: absolute;
              bottom: -15px;
              font-weight: 600;
            }
          }

          &.checkbox-type {
            input {
              width: auto;
              display: inline-block;
              vertical-align: middle;
              margin-top: -2px;
              height: auto;

              &:focus {
                outline: 1px dashed;
              }

              + .hx-form-label {
                display: inline-block;
                margin-left: 5px;
                text-transform: none;
                font-weight: 600;
                color: #6a7b96;
              }
            }
          }

          input.toggleable {
            ~ .hx-visibility-toggler {
              position: absolute;
              right: 8px;
              bottom: 8px;
              background: none;
              border: 0;
              font-size: 9px;
              color: #808fa4;
              font-weight: 600;
              display: none;
              cursor: pointer;

              &::after {
                content: attr(data-value);
              }

              &.active::after {
                content: attr(data-opposite-value);
              }
            }

            &:focus {
              padding-right: 34px;

              ~ .hx-visibility-toggler {
                display: block;
              }
            }
          }
        }

        > a {
          display: inline-block;
          margin-top: 14px;
        }
      }

      a {
        text-decoration: none;

        &:focus {
          text-decoration: underline;
        }
      }

      a.highlighted {
        color: #099cff;
      }

      a.hx-forgot-pass {
        flex-basis: 35%;
      }

      .button {
        line-height: 28px;
        padding: 0 20px;
        border-radius: 6px;
        font-size: 13px;
        cursor: pointer;
        border: solid 1px #dfe5ef;
        color: #677a91;
        min-width: 90px;

        &.loading {
          color: transparent !important;
          position: relative;

          &::after {
            position: absolute;
            top: 0;
            font-size: 28px;
            width: 1em;
            height: 1em;
            line-height: 1;
            content: '';
            display: block;
            background: transparent url(../assets/spinner.svg) no-repeat center center;
            background-size: contain;
            animation: rotate 1s infinite steps(12);
            left: 50%;
            margin-left: -0.5em;
          }
        }

        &[disabled] {
          opacity: 0.5;
          cursor: default;
        }

        &:focus {
          text-decoration: underline;
        }

        &.primary {
          background-color: #459afe;
          color: white;
        }

        &.transparent {
          padding: 0;
          font-size: 12px;
          border: 0;
        }
      }

      footer {
        display: flex;
        justify-content: space-between;
        margin-top: 50px;

        &.right-aligned {
          justify-content: flex-end;
        }

        .button:not(:last-child) {
          margin-right: 16px;
        }
      }
    }

    &.hx-single-column-layout {
      width: 400px;

      section {
        > * {
          flex-basis: 100%;
        }
      }
    }

    &.hx-double-column-layout {
      width: 540px;

      section label {
        flex-basis: calc(50% - 12px);

        &:nth-child(2n-1) {
          margin-right: 24px;
        }

        &.wide {
          flex-basis: 100%;
          margin-right: 0;
        }
      }

      @media (max-width: 1024px), (orientation: portrait) {
        width: 400px;

        section {
          > *,
          label {
            flex-basis: 100%;

            &:nth-child(2n-1) {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  .hx-validation-infoblock {
    display: block;
    position: absolute;
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 rgba(34, 50, 71, 0.2);
    background-color: #ffffff;
    padding: 12px 16px;
    list-style: none;
    margin-top: 1rem;
    z-index: 1;

    sh-icon {
      display: inline-block;
      margin: 2px;
      margin-left: 0;
    }
  }
}
