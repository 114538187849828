.hx-staff-avatar {
	background: #dae0ee url(../../assets/avatar-placeholder.svg) center center no-repeat;
	background-size: 50%;
	border-radius: 50%;
}

.hx-staff-initials {
	border: 1px solid #cacecd;
	background-color: #dae0ee;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 0.53846em;
	border-radius: 50%;

	&:after {
		content: attr(data-name);
		height: 100%;
		display: -webkit-flex;
		display: flex;
		-webkit-justify-content: center;
		justify-content: center;
		-webkit-align-items: center;
		align-items: center;
		overflow: hidden;
	}
}
