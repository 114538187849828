body {
  color: #5e6069;
  background-color: #eff1f5;
  font-size: 13px !important;
  overflow: auto !important;
}

a {
  color: #5e6069;
}

a:hover {
  color: #5e6069;
}

h1.title,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  color: #5e6069;
  text-rendering: optimizeLegibility;
  margin-bottom: 13px;
}

.he-header {
  background-color: #2c343e;
  .he-nav {
    justify-content: initial;
    padding-left: 3.5em;
  }
}

.darkgray1 {
  color: #526173;
}

.he-header-logo {
  position: absolute;
  width: 76px;
  height: 76px;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.25);

  img {
    position: absolute;
    left: 19px;
    width: 40px;
  }
}

.inactive-submenu {
  &:hover {
    opacity: 1 !important;
  }

  opacity: 0.5 !important;
}

.active-submenu {
  opacity: 100 !important;
  cursor: default;
  letter-spacing: -0.1px;
  color: #ffffff;
}

.open2 {
  margin-right: 22px;
  position: fixed;
}

.he-header-account {
  position: relative;
}

.he-account-dropdown aside.tiny.text-right.opened-dropdown-aside {
  margin-right: 9px !important;
}

.form-header {
  font-weight: 700;
  text-rendering: optimizeLegibility;
  letter-spacing: -0.1px;
  font-size: 18px;
  line-height: 44px;
  color: #ffffff;
  background-color: #434f5d;
  padding: 0 0 0 20px;
}

a.reset {
  cursor: pointer;
}

.admin-table td:first-child,
.admin-table td:last-child {
  padding-right: 0.833333333333333em;
  padding-left: 0.833333333333333em;
}

.admin-table .row-in-group td:first-child,
.admin-table .row-in-group td:last-child {
  padding-left: 1.666666666666667em;
  padding-right: 1.666666666666667em;
}

.admin-table.clickable tbody tr:hover {
  background-color: #91909021;
}

.button.secondary {
  background-color: transparent;
  border: 1px solid #dfe5ef;

  &:hover {
    background-color: #fbfbfb;
  }
}

#root {
  height: 100%;
}

.hx-header {
  width: 100%;
  top: 0;
  z-index: 102;
}

.modal footer {
  min-height: 72px;
}

.pb0 {
  padding-bottom: 0;
}

a.disabled {
  pointer-events: none;
  cursor: default;
}

.icon {
  vertical-align: middle;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.shui-link:hover {
  color: rgb(236, 102, 2);
  opacity: var(--opacity-1);
  transition: var(--transition-time) all ease-in-out;
}

.sh-link {
  color: var(--text-primary) !important;
  cursor: pointer;
  text-decoration: underline !important;
}

.sh-link:hover {
  color: rgb(236, 102, 2) !important;
  opacity: var(--opacity-1) !important;
  transition: var(--transition-time) all ease-in-out;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.no-margin-left {
  margin-left: 0;
}

.full-width {
  width: 100%;
}

.external-link {
  cursor: pointer;
  text-decoration: none;
}

.information-body-container {
  display: flex;
  margin-bottom: 1vh;
  color: black;

  .information-checkbox {
      width: 3vw;
  }

  .information-body {
      text-align: justify;
  }
}